import { Injectable } from '@angular/core';
import { rmaShareReplay } from '@rma/generic/util/operators/combined';
import {
  BillingUserContextService,
  ProductFilter,
  ProductModel,
  ProductType,
  ProductUserType,
  ProductUserTypeGrouping,
} from '@rma/subscriptions/public-api/da-billing-context';
import { map, Observable, of, switchMap } from 'rxjs';
import { SubscriptionApiService } from '../data-access/subscription-api.service';
import { SubscriptionAccessLevel } from './subscription-plan-access.model';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionProductService {
  public constructor(
    private readonly billingUserContextService: BillingUserContextService,
    private readonly subscriptionApiService: SubscriptionApiService,
  ) {}

  public getSubscriptionAccessLevel(
    productUserTypeGrouping: ProductUserTypeGrouping,
    userType: ProductUserType,
  ): Observable<SubscriptionAccessLevel> {
    return this.subscriptionApiService.getSubscriptionAccessLevel(productUserTypeGrouping, userType);
  }


  public get currentSubscriptionProducts$(): Observable<ProductModel[] | null> {
    return this.billingUserContextService.userContext$.pipe(
      switchMap((context) =>
        context
          ? this.subscriptionApiService.getProductsForUser(
              context.productUserType,
              context.productUserGroupingType,
              context.code,
              ProductType.Subscription,
            )
          : of(null),
      ),
      rmaShareReplay(),
    );
  }

  public getProducts(filter?: ProductFilter): Observable<ProductModel[]> {
    return this.subscriptionApiService.getProducts(filter);
  }


  public get availableTrialDays$(): Observable<number> {
    return this.currentSubscriptionProducts$.pipe(
      map((products) => products?.filter((product) => product.prices?.filter((price) => price.trialPeriodDays))),
      map((products) =>
        products
          ?.map((product) => product?.prices ?? [])
          ?.reduce((a, b) => a.concat(b), [])
          ?.filter((price) => price.trialPeriodDays ?? 0),
      ),
      map(
        (prices) =>
          prices
            ?.map((price) => price.trialPeriodDays)
            ?.sort()
            .reverse()[0] ?? 0,
      ),
    );
  }


  public get hasTrialAccess$(): Observable<boolean> {
    return this.availableTrialDays$.pipe(map((d) => d > 0));
  }
}
