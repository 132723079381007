import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RmaUrlsService } from '@rma/generic/util/environment';
import { httpParamMapper } from '@rma/generic/util/http';
import {
  ProductFilter,
  ProductModel,
  ProductType,
  ProductUserType,
  ProductUserTypeGrouping,
  SubscriptionModel,
} from '@rma/subscriptions/public-api/da-billing-context';
import { Observable } from 'rxjs';
import { SubscriptionSummary } from '../domain/current-subscription.model';
import { SubscriptionAccessLevel, SubscriptionPlanAccess } from '../domain/subscription-plan-access.model';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionApiService {
  public constructor(
    private readonly httpClient: HttpClient,
    private readonly rmaURls: RmaUrlsService,
  ) {}

  public getSubscriptionAccessLevel(
    productUserTypeGrouping: ProductUserTypeGrouping,
    userType: ProductUserType,
  ): Observable<SubscriptionAccessLevel> {
    const url = this.rmaURls.apiUrl(`/SubscriptionAccess/${productUserTypeGrouping}-${userType}`);

    return this.httpClient.get<SubscriptionAccessLevel>(url);
  }

  public getCurrentAgentSubscriptionSummary(): Observable<SubscriptionSummary> {
    const url = this.rmaURls.apiUrl('Agents/Me/Subscription/summary');

    return this.httpClient.get<SubscriptionSummary>(url);
  }

  public getCurrentAgencySubscriptionSummary(agencyCode: string): Observable<SubscriptionSummary> {
    const url = this.rmaURls.apiUrl(`Agencies/Code-${agencyCode}/Subscription/Summary`);

    return this.httpClient.get<SubscriptionSummary>(url);
  }

  public getCurrentSubscription(
    productUserType: ProductUserType,
    productUserTypeGrouping: ProductUserTypeGrouping,
    code: string,
  ): Observable<SubscriptionModel> {
    const url = this.rmaURls.apiUrl(`${productUserType}/${productUserTypeGrouping}-${code}/Subscriptions`, 'Billing');

    return this.httpClient.get<SubscriptionModel>(url);
  }

  public getProducts(filter?: ProductFilter): Observable<ProductModel[]> {
    const url = this.rmaURls.apiUrl('Products', 'Billing');

    const params = { params: httpParamMapper(filter) };

    return this.httpClient.get<ProductModel[]>(url, params);
  }

  public getProductsForUser(
    productUserType: ProductUserType,
    productUserTypeGrouping: ProductUserTypeGrouping,
    code: string,
    productType: ProductType,
  ): Observable<ProductModel[]> {
    const url = this.rmaURls.apiUrl(`${productUserType}/${productUserTypeGrouping}-${code}/${productType}/Products`, 'Billing');

    return this.httpClient.get<ProductModel[]>(url);
  }

  public getCurrentAgentAccess(): Observable<SubscriptionPlanAccess> {
    const url = this.rmaURls.apiUrl('Agents/Me/Subscription/Access');

    return this.httpClient.get<SubscriptionPlanAccess>(url);
  }

  public getAgencyAccess(agencyCode: string): Observable<SubscriptionPlanAccess> {
    const url = this.rmaURls.apiUrl(`Agencies/Code-${agencyCode}/Subscription/Access`);

    return this.httpClient.get<SubscriptionPlanAccess>(url);
  }
}
