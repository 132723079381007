import { Injectable } from '@angular/core';
import { CurrentUserContext, CurrentUserService, UserType } from '@rma/accounts/user';
import { VerticalType } from '@rma/generic/domain/types';
import { rmaShareReplay } from '@rma/generic/util/operators/combined';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BillingUserContext } from './domain/billing-user-context.model';
import { ProductUserType, ProductUserTypeGrouping } from './domain/billing.model';

@Injectable({
  providedIn: 'root',
})
export class BillingUserContextService {
  constructor(private readonly userService: CurrentUserService) {}


  public get userContext$(): Observable<BillingUserContext | null> {
    return this.userService.currentUserContext$.pipe(
      map((profile) => mapBillingUserContext(profile)),
      rmaShareReplay(),
    );
  }
}

function mapBillingUserContext(user: CurrentUserContext | null): BillingUserContext | null {
  if (user === null || user.userType === UserType.Consumer) return null;

  if (user.userType === UserType.Agent) {
    return {
      code: user.profileCode,
      productUserType: user.verticalType === VerticalType.Finance ? ProductUserType.MortgageBroker : ProductUserType.RealEstate,
      productUserGroupingType: ProductUserTypeGrouping.Individual,
    };
  } else {
    return {
      code: user.profileCode,
      productUserType: user.verticalType === VerticalType.Finance ? ProductUserType.MortgageBroker : ProductUserType.RealEstate,
      productUserGroupingType: user.isTeam ? ProductUserTypeGrouping.Team : ProductUserTypeGrouping.Office,
    };
  }
}
