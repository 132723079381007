import { Injectable } from '@angular/core';
import {
  PriceModel,
  PriceRecurringInterval,
  PriceTierMode,
  ProductFilter,
  ProductModel,
  ProductType,
  ProductUserType,
  ProductUserTypeGrouping,
} from '@rma/subscriptions/public-api/da-billing-context';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CurrentSubscription } from './current-subscription.model';
import { CurrentSubscriptionService } from './current-subscription.service';
import { SubscriptionPlan, SubscriptionPlanFilter, SubscriptionPlanPrice } from './subscription-plan.model';
import { SubscriptionProductService } from './subscription-product.service';
import { GetSubscriptionTier, SubscriptionTiers } from './subscription-tiers.model';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionPlanService {
  constructor(
    private readonly currentSubscriptionService: CurrentSubscriptionService,
    private readonly subscriptionProductService: SubscriptionProductService,
  ) {
  }


  public get currentSubscriptionPlans$(): Observable<SubscriptionPlan[] | null> {
    return combineLatest([
      this.subscriptionProductService.currentSubscriptionProducts$,
      this.currentSubscriptionService.currentSubscription$,
    ]).pipe(
      map(([products, sub]) =>
        products && sub ? products.map((z, i) => mapPlan(z, products.length, i > 0 ? products[i - 1] : undefined, sub)) : null,
      ),
    );
  }

  public getSubscriptionPlans(filter: SubscriptionPlanFilter): Observable<SubscriptionPlan[]> {
    const filters: ProductFilter = {
      productType: ProductType.Subscription,
      productUserType: filter.productUserType,
      productUserTypeGroupings: filter.productUserTypeGroupings,
    };

    return combineLatest([this.subscriptionProductService.getProducts(filters), this.currentSubscriptionService.currentSubscription$]).pipe(
      map(([x, z]) => {
        const subscriptionPlans = x.map((q, i) => mapPlan(q, x.length, i > 0 ? x[i - 1] : undefined, getSubscriptionConfig(z, q)));
        return this.ensureOneIsPopularSubscriptionPlan(subscriptionPlans);
      }),
    );
  }

  private ensureOneIsPopularSubscriptionPlan(subscriptionPlans: SubscriptionPlan[]) {
    let previousIsPopular = false;
    subscriptionPlans.forEach((plan) => {
      if (previousIsPopular && plan.isPopular) {
        plan.isPopular = false;
      }
      if (plan.isPopular) {
        previousIsPopular = true;
      }
    });
    return subscriptionPlans;
  }
}

interface CurrentSubscriptionConfig {
  isFree: boolean;
  tier: SubscriptionTiers;
  isProvided: boolean;
  hasCancelled: boolean;
  teamMembers?: number;
  onTrial: boolean;
  startedWithReverseTrial?: boolean;
}

function mapPlan(
  product: ProductModel,
  amountOfProducts: number,
  previousProduct?: ProductModel,
  subscription?: CurrentSubscriptionConfig,
): SubscriptionPlan {
  const defaultInterval = PriceRecurringInterval.Year;
  const primaryUncalculatedPrice =
    product.prices?.find((x) => x.interval === defaultInterval && x.intervalCount === 1) ?? product.prices?.find((x) => !!x.interval);

  const price = calculatePrice(primaryUncalculatedPrice, true, subscription?.teamMembers);
  const tier = GetSubscriptionTier(product.tier);

  const secondaryUncalculatedPrice =
    primaryUncalculatedPrice?.interval === defaultInterval
      ? product.prices?.find((x) => !!x.interval && x.interval !== defaultInterval && x.intervalCount === 1)
      : undefined;

  const secondaryPrice = secondaryUncalculatedPrice
    ? calculatePrice(secondaryUncalculatedPrice, true, subscription?.teamMembers)
    : undefined;

  const tierFeatures = product.productUserTypeGrouping === previousProduct?.productUserTypeGrouping
    ? product.features?.filter((x) => previousProduct?.features?.includes(x) !== true) ?? []
    : product.features;

  return {
    id: product.id,
    name: product.displayName ?? '',
    tier,
    type: product.productUserTypeGrouping,
    isPopular: isPopular(tier, amountOfProducts),
    isFinance: product.productUserType === ProductUserType.MortgageBroker,
    price,
    secondaryPrice,
    description: `subscriptions.plans.tier.${product.productUserTypeGrouping}.${tier}.description`.toLowerCase(),
    includes: `subscriptions.plans.tier.${product.productUserTypeGrouping}.${tier}.includes`.toLowerCase(),
    features: product.features ?? [],
    tierFeatures,
    initialQuantity: subscription?.teamMembers ?? 1,
    trialDays: primaryUncalculatedPrice?.trialPeriodDays ?? 0,
    isSubscribed: subscription
      ? subscription.tier === GetSubscriptionTier(product.tier) && !subscription.isProvided && !subscription.hasCancelled
      : false,
    isSubscriber: subscription ? subscription && !subscription.isFree && !subscription.hasCancelled : false,
    isReverseTrialer: subscription ? subscription.onTrial && subscription.startedWithReverseTrial === true : false,
    checkoutUrl: getCheckoutUrl(tier, product, subscription, product.contactForPriceInformation),
    contactForPriceInformation: product.contactForPriceInformation,
  };
}

function calculatePrice(price: PriceModel | undefined, applyDefaultDiscount: boolean, teamCount?: number): SubscriptionPlanPrice {
  if (!price) {
    return {
      priceAmount: 0,
      period: PriceRecurringInterval.Year,
      priceIncludesTeamSizeCount: 0,
      maxTeamSize: 0,
    };
  }

  let priceAmount = price.priceAmount;
  let unitAmount: number | undefined;
  let priceIncludesTeamSizeCount = 0;
  let maxTeamSize = 0;

  // display "tiered" pricing if more than 2 tiers i.e. from....
  const isTiered = teamCount === undefined && price.priceTiers?.length > 2;

  if (price.priceTiers?.length > 0) {
    const productPricing = price.tiersMode === PriceTierMode.Volume ? calculateVolumePricing(price) : calculateGraduatedPricing(price);

    priceAmount = productPricing.priceAmount ?? 0;
    unitAmount = productPricing.unitAmount;
    priceIncludesTeamSizeCount = productPricing.priceIncludesTeamSizeCount ?? 0;
    maxTeamSize = productPricing.maxTeamSize ?? 0;
  }

  if (applyDefaultDiscount) {
    if (price.defaultCoupon?.amountOff) {
      priceAmount -= price.defaultCoupon?.amountOff;
    }
    if (price.defaultCoupon?.percentOff) {
      priceAmount *= (100 - price.defaultCoupon.percentOff) / 100;
      if (unitAmount) {
        unitAmount *= (100 - price.defaultCoupon.percentOff) / 100;
      }
    }
  }

  return {
    currency: price.currency,
    priceAmount,
    unitAmount,
    monthlyPrice: price.interval === PriceRecurringInterval.Year ? Math.round(priceAmount / 12) : undefined,
    monthlyUnitAmount: price.interval === PriceRecurringInterval.Year && unitAmount ? Math.round(unitAmount / 12) : undefined,
    period: price.interval,
    isTiered,
    priceIncludesTeamSizeCount,
    minimumTeamSizeCount: price.minimumTeamSize,
    maxTeamSize,
  };
}

function calculateVolumePricing(price: PriceModel): ProductPricing {
  const calculateUsingQuantity = price.minimumTeamSize ?? 0;
  const priceTier =
    price.priceTiers.find((x) => x.maxTeamSize && x.maxTeamSize > calculateUsingQuantity) ?? price.priceTiers?.find((x) => !x.maxTeamSize);

  return {
    priceAmount: priceTier?.flatAmount ?? 0,
    unitAmount: priceTier?.unitAmount ?? 0,
    priceIncludesTeamSizeCount: calculateUsingQuantity,
    maxTeamSize: priceTier?.maxTeamSize ?? 15, // Volume prices are currently used. If someone creates one with a maxTeamSize, the cards will still work,
  };
}

function calculateGraduatedPricing(price: PriceModel): ProductPricing {
  const minimumTeamSize = price.minimumTeamSize ?? 0;
  // getting by index, so we can get all the tiers below the minimum
  const minimumTierIndex = price.priceTiers.findIndex((pt) => (pt.maxTeamSize ?? 0) > minimumTeamSize);

  const minimumTier = price.priceTiers[minimumTierIndex];

  const tiersLowerThanMiniumTeamSize = price.priceTiers.filter((_, index) => index < minimumTierIndex);

  //It is possible for the tiers to set the flat amount, however they should inherit from the lower tiers
  const priceAmount = tiersLowerThanMiniumTeamSize
    .map((p) => ({ flatAmount: p.flatAmount ?? 0, unitAmount: p.unitAmount ?? 0, maxTeamSize: p.maxTeamSize ?? 0 }))
    .reduce((accum, curr) => curr.flatAmount + curr.unitAmount * curr.maxTeamSize + accum, minimumTier?.flatAmount ?? 0);

  const priceIncludesTeamSizeCount = tiersLowerThanMiniumTeamSize.reduce((accum, curr) => curr?.maxTeamSize ?? accum, 0);

  return {
    priceAmount,
    unitAmount: minimumTier.unitAmount,
    maxTeamSize: minimumTier.maxTeamSize,
    priceIncludesTeamSizeCount: priceIncludesTeamSizeCount,
  };
}

function isPopular(tier: SubscriptionTiers, amountOfProduct: number) {
  return tier === SubscriptionTiers.Professional && amountOfProduct > 1;
}

function getCheckoutUrl(
  tier: SubscriptionTiers,
  product: ProductModel,
  subscription?: CurrentSubscriptionConfig,
  useContactForm?: boolean,
): string {
  const productUserTypeGrouping = product.productUserTypeGrouping;

  if (product.contactForPriceInformation && useContactForm) {
    return '/contactform/teamsandoffices';
  }

  if (subscription) {
    if (subscription.tier === tier) {
      return `/dashboard`;
    }

    if (!subscription.isFree && tier === SubscriptionTiers.Free) {
      return `/dashboard/billing/downgrade`;
    }

    if (productUserTypeGrouping === ProductUserTypeGrouping.Team) {
      return '/dashboard/billing/checkout/team';
    }

    return `/dashboard/billing/checkout?tier=${tier}`;
  }

  if (productUserTypeGrouping !== ProductUserTypeGrouping.Individual && useContactForm) {
    return '/contactform/teamsandoffices';
  }

  const redirect =
    tier === SubscriptionTiers.Free
      ? '/dashboard/?show-welcome=true'
      : productUserTypeGrouping === ProductUserTypeGrouping.Team
        ? '/dashboard/billing/checkout/team'
        : `/dashboard/billing/checkout?tier=${tier}`;

  return `/search-profile?redirectUrl=${encodeURIComponent(redirect)}`;
}

function getSubscriptionConfig(subscription: CurrentSubscription | null, product: ProductModel): CurrentSubscriptionConfig | undefined {
  if (!subscription) {
    return undefined;
  }

  if (
    product.productUserType === subscription.productUserType &&
    product.productUserTypeGrouping === subscription.productUserTypeGrouping
  ) {
    return subscription;
  }
  return undefined;
}

interface ProductPricing {
  priceAmount?: number;
  unitAmount?: number;
  priceIncludesTeamSizeCount?: number;
  maxTeamSize?: number;
}
