export enum SubscriptionTiers {
  Free = 'Free',
  MLSFree = 'MLSFree',
  EarlyAdopter = 'EarlyAdopter',
  Starter = 'Starter',
  Professional = 'Professional',
  Enterprise = 'Enterprise',
}

export enum SubscriptionPlanType {
  Agent = 'Agent',
  Agency = 'Agency',
}

export function GetSubscriptionTierRank(tier: SubscriptionTiers) {
  switch (tier) {
    case SubscriptionTiers.Enterprise:
      return 3;
    case SubscriptionTiers.Professional:
      return 2;
    case SubscriptionTiers.Starter:
      return 1;
    case SubscriptionTiers.EarlyAdopter:
    case SubscriptionTiers.MLSFree:
    case SubscriptionTiers.Free:
    default:
      return 0;
  }
}

export function GetSubscriptionTier(tier?: number) {
  switch (tier) {
    case 300:
      return SubscriptionTiers.Enterprise;
    case 200:
      return SubscriptionTiers.Professional;
    case 100:
      return SubscriptionTiers.Starter;
    case 50:
      return SubscriptionTiers.MLSFree;
    case 25:
      return SubscriptionTiers.EarlyAdopter;
    default:
      return SubscriptionTiers.Free;
  }
}
