import { Injectable } from '@angular/core';
import { CurrentUserService, UserType } from '@rma/accounts/user';
import { rmaShareReplay } from '@rma/generic/util/operators/combined';
import { Observable, of, switchMap } from 'rxjs';
import { SubscriptionApiService } from '../data-access/subscription-api.service';
import { SubscriptionPlanAccess } from './subscription-plan-access.model';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionFeatureService {
  constructor(
    private readonly userService: CurrentUserService,
    private readonly subscriptionApiService: SubscriptionApiService,
  ) {}


  public get currentAccess$(): Observable<SubscriptionPlanAccess | undefined> {
    return this.userService.currentUserContext$.pipe(
      switchMap((currentUserContext) => {
        if (currentUserContext === null || currentUserContext?.userType === UserType.Consumer) {
          return of(undefined);
        }
        return currentUserContext.userType === UserType.Agent
          ? this.subscriptionApiService.getCurrentAgentAccess()
          : this.subscriptionApiService.getAgencyAccess(currentUserContext.profileCode);
      }),
      rmaShareReplay(),
    );
  }
}
