export enum ProductType {
  Subscription = 'Subscription',
  Promoter = 'Promoter',
  Awards = 'Awards',
}

export enum ProductUserType {
  RealEstate = 'RealEstate',
  MortgageBroker = 'MortgageBroker',
}

export enum ProductUserTypeGrouping {
  Office = 'Office',
  Team = 'Team',
  Individual = 'Individual',
}

export enum PriceType {
  Regular = 'Regular',
  Licence = 'Licence',
}
export enum PriceRecurringInterval {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Year = 'Year',
}

export enum SubscriptionOrigin {
  Admin = 'Admin',
  Dashboard = 'Dashboard',
}

export enum SubscriptionStatus {
  Incomplete = 'Incomplete',
  IncompleteExpired = 'IncompleteExpired',
  Trialing = 'Trialing',
  Active = 'Active',
  PastDue = 'PastDue',
  Canceled = 'Canceled',
  Unpaid = 'Unpaid',
}

export const PriceTierMode = {
  Graduated: 'Graduated',
  Volume: 'Volume',
} as const;
export type PriceTierModeType = (typeof PriceTierMode)[keyof typeof PriceTierMode];

export interface ProductFilter {
  productType?: ProductType;
  productUserType?: ProductUserType;
  productUserTypeGroupings?: ProductUserTypeGrouping[];
}

export interface ProductModel {
  id: number;
  tier: number;
  name: string;
  displayName: string;

  productUserType: ProductUserType;
  productUserTypeGrouping: ProductUserTypeGrouping;

  features: string[];
  prices: PriceModel[];
  contactForPriceInformation: boolean;
}

export interface PriceModel {
  id: number;

  interval: PriceRecurringInterval;
  intervalCount: number;

  isEligibleForTrial: boolean;
  trialPeriodDays: number;

  priceAmount: number;
  currency: string;
  minimumTeamSize?: number;
  priceTiers: PriceTierModel[];
  tiersMode?: PriceTierModeType;

  defaultCoupon?: CouponModel;
}

export interface PriceTierModel {
  flatAmount?: number;
  unitAmount?: number;
  maxTeamSize?: number;
}

export interface CouponModel {
  id: string;
  name: string;
  couponCode: string;
  duration: string;
  percentOff?: number;
  amountOff?: number;
}

export interface SubscriptionModel {
  id: number;
  isActive: boolean;

  startDate: string | Date;
  currentPeriodStart: string | Date;
  currentPeriodEnd: string | Date;
  cancelAt?: string | Date;

  subscriptionStatus?: SubscriptionStatus;
  subscriptionOrigin?: SubscriptionOrigin;

  stripeSubscriptionId: string;
  soldBy?: string;

  subscriptionSchedule?: SubscriptionScheduleModel;

  trialStart?: string | Date;
  trialEnd?: string | Date;

  coupon?: CouponModel;

  subscriptionLineItems: SubscriptionLineItemModel[];
  startedWithReverseTrial?: boolean;
}

export interface SubscriptionLineItemModel {
  stripeSubscriptionLineItemId: string;

  priceName?: string;
  quantity?: number;
  priceId?: number;
  productId: number;
  productName: string;
  productType: ProductType;
  interval: PriceRecurringInterval;
  priceType: PriceType;
  priceAmount?: number;
}

export interface SubscriptionScheduleModel {
  stripeSubscriptionScheduleId: string;

  startDate: string | Date;
  endDate: string | Date;

  subscriptionScheduleItems: SubscriptionScheduleItemModel[];
}

export interface SubscriptionScheduleItemModel {
  productId: number;
  productName: string;

  quantity?: number;
  priceId?: number;
  priceName?: string;
}
